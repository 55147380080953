import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 *  게시판  관리 관련 기능 클래스
 */
class BoardAuthority {

    /**
     * 생성자
     */
    constructor() {

        this.systCode = 'TRA';
        this.useYn = 'Y';
        
     }

    /**
     * 게시판 목록 가져오기
     * @returns 
     */
    getBoardAuthorityList() {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'SelectBoardAuthorityList';

            const obj = new Object();
            obj.SystCode = this.systCode;
            obj.UseYn = this.useYn;

            request.parameters = obj;


            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));

        });
    }

    /**
     * 게시판 기능 가져오기
     * @param {string} boardCategory 
     * @returns 
     */
     getBoardFunctionList(boardCategory) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'SelectBoardFunctionList';

            request.parameters.boardCategory = boardCategory;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
            
        });
    }
    
    /**
     * 게시판 분류 가져오기
     * @param {string} boardCategory 
     * @returns 
     */
     getBoardClassList(boardCategory) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'SelectBoardClassList';

            const obj = new Object();
            obj.SystCode = this.systCode;
            obj.BoardCategory = boardCategory;

            request.parameters = obj;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
            
        });
    }

    /**
     * 게시판 권한 가져오기
     * @param {string} boardCategory 
     * @returns 
     */
     getAuthorityBoardList(boardCategory) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'SelectAuthorityBoardList';

            request.parameters.boardCategory = boardCategory;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                rows.forEach(row => {
                    
                    row.isAccess = row.AccessYn === 1 ? true : false;
                    row.isComment = row.CommentYn === 1 ? true : false;
                    row.isFileDownload = row.FileDownloadYn === 1 ? true : false;
                    row.isReply = row.ReplyYn === 1 ? true : false;
                    row.isTopFix = row.TopFixYn === 1 ? true : false;
                    row.isWrite = row.WriteYn === 1 ? true : false;
                });

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
            
        });
    }

    /**
     * 게시판 관리 저장
     * @param {string} boardCategory
     * @param {object} functions 
     * @param {object} classes 
     * @param {object} authority  
     * @param {string} userID  
     * @returns 
     */
     saveAuthorityBoardInfo(boardCategory, functions, classes, authority, userID) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'InsertAuthorityBoardSave'; 

            request.parameters.SystCode = this.systCode;
            request.parameters.BoardCategory = boardCategory;
            request.parameters.FunctionGrid = JSON.stringify(functions);
            request.parameters.ClassGrid = !!classes ? JSON.stringify(classes) : '[]';
            request.parameters.AuthorityGrid = JSON.stringify(authority);
            request.parameters.UserID = userID;
            

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));

        });
    }

    /**
     * 게시판 권한 로그 저장
     * @param {Object} logInfo 
     * @returns 
     */
     addAuthorityBoardHistory(logInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.CommonBiz';
            request.methodId = 'AddAuthorityBoardHistory';

            request.parameters = logInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));

        });
    }

}

export const boardAuthority = new BoardAuthority();