<template>
    <div class="admin-content">
		<h3>게시판 관리</h3>
		<div class="flex-wrap right">
			<div>
				<div class="btn-wrap">
                    <button type="button" 
                        class="btn-box green"
                        @click="eventHandler.onSaveButtonClick">저장</button>
                    <button type="button" 
                        class="btn-box"
                        @click="{
                            if(!!state.dataTable.selectedBoardCategory) {
                            state.dialog.isOpen=true;
                            }
                        }">분류 추가</button>
				</div>
			</div>
		</div>

        <div class="flex-wrap half mt20">
            <div class="col-4">
                
                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.group.boards === null ? 0 : state.dataTable.group.boards.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.dataTable.group.boards"
                    @row-click="eventHandler.dataTable.group.onRowClick"
                    :resizableColumns="true"
                    :rowHover="true">

                    <template #header>
                        게시판 선택 
                    </template>

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="BoardName" header="게시판명" :sortable="true" style="text-align:center;width:300px;" headerClass="tc"></Column>

                </DataTable>
            </div>

            <div class="col-4">
                                
                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.group.functions === null ? 0 : state.dataTable.group.functions.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.dataTable.group.functions"
                    v-model:selection="state.dataTable.group.functionSelected"
                    @row-select="eventHandler.dataTable.group.onCheck"
                    @row-unselect="eventHandler.dataTable.group.offCheck"
                    @row-select-all="eventHandler.dataTable.group.onAllCheck"
                    @row-unselect-all="eventHandler.dataTable.group.offAllCheck"
                    :resizableColumns="true"
                    :rowHover="true">

                    <template #header>
                        기능 관리
                    </template>

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="IsCheck" selectionMode="multiple" style="text-align:center; width:150px;" headerClass="tc"></Column>
                    <Column field="CheckName" header="기능" :sortable="true" style="text-align:center; width:150px;" headerClass="tc"></Column>

                </DataTable>
            </div>

            <div class="col-4">

                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.group.classes === null ? 0 : state.dataTable.group.classes.length}}</strong></p>
		        </div> 

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.dataTable.group.classes"
                    :resizableColumns="true"
                    :rowHover="true">

                    <template #header>
                        분류 관리
                    </template>

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="ClassName" header="분류명" :sortable="true" style="text-align:center; width:150px;" headerClass="tc"></Column>
                    <Column field="ClassYn" header="사용유무" :sortable="true" style="text-align:center; width:150px;" headerClass="tc">
                        <template #body="{data}">
                            <div style="display: flex; justify-content: center;">
                                <div class="select-box">
                                   <select v-model="data.ClassYn">
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                   </select>
                                </div>
                            </div>
                        </template>
                    </Column>

                </DataTable>
            </div>

            <div class="col-12 mt30">
                                                                
                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.group.authority === null ? 0 : state.dataTable.group.authority.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    columnResizeMode="fit"
                    :value="state.dataTable.group.authority"
                    :resizableColumns="true"
                    :rowHover="true">

                    <template #header>
                        권한 관리
                    </template>

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="GroupName" header="구분" :sortable="true" style="text-align:center; width:130px;" headerClass="tc"></Column>

                    <Column field="AccessYn" header="접근" :sortable="true" style="text-align:center; width:130px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isAccess" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>

                    <Column field="WriteYn" header="글작성" :sortable="true" style="text-align:center; width:130px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isWrite" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>

                    <Column field="ReplyYn" header="답글작성" :sortable="true" style="text-align:center; width:130px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isReply" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>

                    <Column field="CommentYn" header="댓글작성" :sortable="true" style="text-align:center; width:130px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isComment" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>

                    <Column field="TopFixYn" header="상단글고정" :sortable="true" style="text-align:center; width:130px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isTopFix" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>

                    <Column field="FileDownloadYn" header="파일다운로드" :sortable="true" style="text-align:center; width:130px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isFileDownload" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>

                </DataTable>
            </div>    
        </div>
    
        <Dialog 
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1">
                <div>
                    <label>분류명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" type="text" ref="className" v-model.trim="state.dataTable.group.addClass.ClassName" maxlength="50"/>
                    </div>
                </div>
                <div>
                    <label>사용여부 <i class="required">*</i></label>
                    <div class="select-box">
                        <select v-model="state.dataTable.group.addClass.ClassYn" ref="classYn">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                       </select>
                    </div>
                </div>   
            </div>
            <br>
            <div class="flex-wrap right">
	    		<div class="ml10">
	    			<div class="btn-wrap">
                        <button type="button" 
                        class="btn-box gray"
                        @click="methods.classValidation">생성</button>
	    			</div>
	    		</div>
	    	</div>
        </Dialog>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { boardAuthority } from '@/modules/site-training/board-authority.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager.js';
export default {
    components: { },
    setup() {
        
        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const className = ref(); // 분류명
        const classYn = ref(); // 사용여부

        // state
        const state = reactive({

            // 데이터 테이블 관련
            dataTable: {
                
                // 그룹
                group: {

                    // 게시판 목록
                     boards: new Array(),

                    // 기능 목록
                    functions: new Array(),

                    // 기능 선택 목록
                    functionSelected : new Array(),

                    //분류 목록
                    classes: new Array(),

                    //분류 목록 추가 
                    addClass: new Object(),

                    //권한 목록
                    authority: new Array(),

                },
                //선택한 게시판
                selectedBoardCategory:'',
                
            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "분류 관리",

                // 다이얼로그 오픈여부
                isOpen: false,

            },
            
        });

        // 메서드
        const methods = {

            // 게시판 목록 가져오기
            getBoardAuthorityList: () => {

                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                boardAuthority.getBoardAuthorityList().then(groups => {

                    state.dataTable.group.boards = groups;

                    }).finally(() => store.commit('setLoadingStatus', false));

            },

            // 게시판 기능 가져오기
            getBoardFunctionList: (boardCategory) => {

                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                boardAuthority.getBoardFunctionList(boardCategory).then(groups => {

                    //IsCheck 선택 값이 1 = true 인 값만 functionSelected 배열에 옮긴다.
                    state.dataTable.group.functions = groups;
                    state.dataTable.group.functions.forEach(item => {

                        if (item.IsCheck === 1) {
                            state.dataTable.group.functionSelected.push(item);
                        };

                    });

                }).finally(() => store.commit('setLoadingStatus', false));

            },

            // 게시판 분류 가져오기
            getBoardClassList: (boardCategory) => {

                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                boardAuthority.getBoardClassList(boardCategory).then(groups => {

                    state.dataTable.group.classes = groups;

                }).finally(() => store.commit('setLoadingStatus', false));

            },

             // 게시판 권한 가져오기
             getAuthorityBoardList: (boardCategory) => {

                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                boardAuthority.getAuthorityBoardList(boardCategory).then(groups => {

                    state.dataTable.group.authority = groups;

                }).finally(() => store.commit('setLoadingStatus', false));

            },

            // 게시판 관리 저장
            saveAuthorityBoardInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                boardAuthority.saveAuthorityBoardInfo(
                    state.dataTable.selectedBoardCategory,
                    state.dataTable.group.functions,
                    state.dataTable.group.classes,
                    state.dataTable.group.authority,
                    store.getters.getUserId)
                
                .then((response) => {

                    if(response.data.success) {
        
                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getBoardAuthorityList();

                    }

                })
                    
                .finally(() =>{

                    state.dataTable.group.authority.forEach((item) => {

                        /* methods.addAuthorityBoardHistory("U", item.GroupCode);  */
                        
                    })

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
 
                });
                
            },

             //게시판 권한 로그 저장
             addAuthorityBoardHistory: (type, groupCode) => {

                let logInfo = new Object();

                if (type == 'C') { 
                    logInfo.logType = 'C000011';
                }

                else if (type == 'R') {
                    logInfo.logType = 'C000012';
                }

                else if (type == 'U')
                    logInfo.logType = 'C000013';
                
                else if (type == 'D') {
                    logInfo.logType = 'C000014';   
                }

                logInfo.boardCategory = state.dataTable.selectedBoardCategory;
                logInfo.groupCode = groupCode;
                logInfo.device = !store.getters.isMobile ? 'C000018' : 'C000019';
                logInfo.userNo = store.getters.getUserId;
                

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                boardAuthority.addAuthorityBoardHistory(logInfo)
                    
                .then((response) => {

                    if(response.data.success) {

                        console.log("로그 저장되었습니다. data : %o", logInfo);
                    }

                })
                    
                .finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
                
            },
            // 분류 정보 유효성검사
            classValidation: () => {

                let validator = state.dataTable.group.addClass;
                
                // 분류명
                if (!validator.ClassName) {

                    className.value.focus();
                    alertManager.alert.warning('분류명을 입력해주세요');
                }
                // 사용여부
                else if (!validator.ClassYn) {

                    classYn.value.focus();
                    alertManager.alert.warning('사용여부를 선택해주세요');
                }

                else {

                    eventHandler.onClassAddButtonClick();
                }
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            onSaveButtonClick: () => {

                // 기능 관리에 분류가 체크 되었는지 바인딩 - ture or false;
                let functionCheck;

                if(!state.dataTable.selectedBoardCategory) {
                    alertManager.alert.warning("게시판 항목을 선택해 주세요.");
                    return;
                };

                // 기능 관리에 분류가 체크 되었는지 확인
                state.dataTable.group.functionSelected.forEach((item) => {
                    if (item.CheckName === '분류') {
                        functionCheck = true;
                    }
                });

                // 기능 관리에  분류 체크가 되어있고 분류 테이블 정보가 없을때
                if(functionCheck && !state.dataTable.group.classes){

                        alertManager.alert.warning("분류 체크시 분류 관리에 항목이 1개 이상이 필요합니다.");
                }

                else{
                    alertManager.confirm.save().then(() => {

                        //체크박스 권한 선택한값 전달
                        state.dataTable.group.authority.forEach(row => {

                            row.AccessYn = row.isAccess ? 1 : 0;
                            row.CommentYn = row.isComment ? 1 : 0;
                            row.FileDownloadYn = row.isFileDownload ? 1 : 0;
                            row.ReplyYn = row.isReply ? 1 : 0;
                            row.TopFixYn = row.isTopFix ? 1 : 0;
                            row.WriteYn = row.isWrite ? 1 : 0;

                        });

                        methods.saveAuthorityBoardInfo();

                    });
                }
            },
            // 분류 추가 이벤트
            onClassAddButtonClick: () => {

                state.dataTable.group.addClass.SystCode = 'TRA';
                state.dataTable.group.addClass.BoardCategory = state.dataTable.selectedBoardCategory;
                state.dataTable.group.addClass.ClassCode = ''
                
                if (!!state.dataTable.group.classes) {

                    state.dataTable.group.classes.push(state.dataTable.group.addClass);

                } else {

                    state.dataTable.group.classes = new Array(state.dataTable.group.addClass);

                }

                state.dialog.isOpen = false;

            },

            // 데이터 테이블 관련
            dataTable: {

                // 그룹
                group: {

                    // 행 클릭 이벤트 처리
                    onRowClick: event => {

                        //기능 선택 목록 초기화
                        state.dataTable.group.functionSelected = new Array();

                        //분류 목록 초기화
                        state.dataTable.group.classes = new Array();

                        //권한 목록 초기화
                        state.dataTable.group.authority = new Array();

                        //선택한 그룹 그룹코드 바인딩
                        state.dataTable.selectedBoardCategory = event.data.BoardCategory;

                        // 게시판 기능 목록 조회
                        methods.getBoardFunctionList(state.dataTable.selectedBoardCategory);

                        // 게시판 분류 목록 조회
                        methods.getBoardClassList(state.dataTable.selectedBoardCategory);

                        // 게시판 권한 목록 조회
                        methods.getAuthorityBoardList(state.dataTable.selectedBoardCategory);
                        
                    },

                    // 기능 관리 - 체크 박스 체크 이벤트 처리
                    onCheck: event => {

                        state.dataTable.group.functions.forEach(item => {

                            if (item.CheckCode === event.data.CheckCode) {
                                item.IsCheck = 1;
                            }

                        });

                    },
                    
                    // 기능 관리 - 체크 박스 체크해제 이벤트 처리
                    offCheck: event => {

                        state.dataTable.group.functions.forEach(item => {

                            if (item.CheckCode === event.data.CheckCode) {
                                item.IsCheck = 0;
                            }

                        });

                    },

                    // 기능 관리 - 체크 박스 전체 체크 이벤트 처리
                    onAllCheck: () => {

                        state.dataTable.group.functions.forEach(item => {

                            item.IsCheck = 1;

                        });

                    },

                    // 기능 관리 - 체크 박스 전체 체크해제 이벤트 처리
                    offAllCheck: () => {

                        state.dataTable.group.functions.forEach(item => {

                            item.IsCheck = 0;

                        });

                    },

                },
            },

             // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {

                    //분류 추가 사용여부 디폴트 값
                    state.dataTable.group.addClass.ClassYn = "Y";

                },

                // hide 이벤트 처리
                hide: () => {

                    //분류 추가 초기화
                    state.dataTable.group.addClass = new Array();

                },
    
            },
            
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 그룹관리 초기조회
            methods.getBoardAuthorityList();

        });

        return {
            state,
            methods,
            eventHandler,
            className,
            classYn,
        }
    }
}
</script>